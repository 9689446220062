import React, {Fragment} from 'react';
import { Link } from "react-router-dom";

class NavSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      collapsed: (this.props.category.name === 'Standard' && this.props.curated.length === 0) || (this.props.category.name === 'Display' && this.props.curated.length === 0) ? true : false 
    };
  }

  handleClick = () => {
    this.setState({collapsed: !this.state.collapsed});
  }

 
  render() {
    const { category, curated } = this.props;
    const { collapsed } = this.state;
    const collapsedClass = collapsed ? 'nav-bar__menu-options--collapsed' : ''; 
    const arrowClass = collapsed ? 'arrow-icon--collapsed' : ''; 
    const headerClass = collapsed ? 'nav-bar__menu-header--collapsed' : ''; 

    if(curated.length > 0) {
      const curatedUnits = category.units.filter(unit => curated.includes(unit.id));
      category.units = curatedUnits.length > 0 ? curatedUnits : [];
    }

    if(category.units.length > 0) {
      return (
        <Fragment key={category.key}>
            <h4 onClick={ this.handleClick } className={ `nav-bar__menu-header ${headerClass} gds-text--header-xs gds-text--bold` }>{category.name}<i className={`arrow-icon ${arrowClass} fal fa-angle-down -m-l-2`}/></h4>
            <ul className={`nav-bar__menu-options ${collapsedClass}`}>
              {
                category.units.map((item) => {
                  return ( <Link key={item.key}  to={item.path}><li key={item.key} className="nar-bar__menu-option">{item.label}</li></Link> )
                })
              }
            </ul>
        </Fragment>
      )
    } else {
      return null;
    }
  }
}

export default NavSection;
