import React from 'react';
import { navContent } from '../constants/constants';
import { withRouter } from "react-router-dom";

class MobileFooter extends React.Component {
	
  handleChange = (e) => {
    this.props.history.push(`${e.target.value}`);
  }
	render() {
    const params = new URLSearchParams(this.props.location.search);
    const isolated = params.get('isolated');
    return (
      <div className="mobile-footer">
        {!isolated && 
          <div className="mobile-footer__inner">
            <span className="divider-horizontal divider-horizontal--top"/>
          	<select className="mobile-nav-input" value={this.props.location.pathname} onChange={this.handleChange}>
          		{
                navContent.map((category) => {
                  return (
                    category.units.map((unit) => {

                      return ( <option key={unit.key} value={unit.path}>{unit.label}</option> )
                    }) 
                  )
                }) 
              }
          	</select>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(MobileFooter);
