import React from 'react';
import { withRouter } from 'react-router-dom';
import mark from '../mark.svg';
import Curator from './Curator';
import { navContent } from '../constants/constants';

class Content extends React.Component {

  render() {

    const pathname = this.props.location.pathname

    let unit, path;
    
    if(document.location.href === 'https://spec-sheet-uk.gumgum.com/') {
      window.location.href = 'https://specs.gumgum.com/#/uk';
    }

    switch(pathname) {
      case '/':
      case '/home':
        unit = 'home';
        path = unit;
        break;
      case '/curator':
        unit = 'curator';
        path = 'curator';
        break;
      default:
        unit = navContent.map((category) => {
          return category.units.filter( (unit) => { return unit.path ===  pathname});
        }).filter( (el) => { return el.length > 0});
        path = unit.length === 0 ? undefined : unit[0][0].url;
        break;
    }

    return (
      <div className="gds-flex__item gds-flex gds-flex--justify-center gds-flex--align-center gds-flex--content-center" style={{height: '100%', 'padding': '1rem'}}>
        <img alt="stamp" src={mark} height="100" className="image-stamp"/>
        { path === 'home' && (
            <h1 className="gds-text--header-sm">Home</h1>
          )
        }
        { path === 'curator' && (
            <Curator/>
          )
        }
        {
          path !== 'home' && path !== 'curator' && path !== undefined && (
            <iframe title="content card" src={path} frameBorder="0" width="100%" height="100%" style={{ width: '100%', zIndex: 1}} allowFullScreen/>
          )
        }
        {
          path === undefined && (
            <h1 style={{top: '100px'}} className="gds-text--header-md -color-tx-lt-2">Unit Not Found!</h1>
          )  
        }
      </div>
    )
  }
}

export default withRouter(Content);
