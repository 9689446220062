import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import logo from '../logo.svg';
import { navContent } from '../constants/constants';
import NavSection from './NavSection';

class Nav extends React.Component {

  render() {

    const params = new URLSearchParams(this.props.location.search);
    const isolated = params.get('isolated');

    const curated = params.get('curate') ? params.get('curate').split(',').map(x=>+x) : [];
    console.log(curated);

    return (
      <Fragment>
        {!isolated && 
        <nav className="nav-bar -none--sm">
            <div className="nav-bar__inner">
              <Link to={'./'}>
                <header className="nav-bar__header">
                  <img className="nav-bar__logo" alt="logo" src={logo} height="21"/>
                  <h1 className="nav-bar__title gds-text--header-sm gds-text--hero -text-tr-up">Product Specs</h1>
                  <span className="divider-horizontal"/>
                </header>
              </Link>
              <menu className="nav-bar__menu -text-right">
                {navContent.map((category) => {
                    return (
                      <NavSection key={category.key} category={category} curated={curated}/>
                    )
                  }) 
                }
              </menu>
              <span className="divider-vertical"/>
            </div>
        </nav>
        }
      </Fragment>
    )
  }
}

export default withRouter(Nav);
