import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import './App.css';
import Content from './components/Content';
import Nav from './components/Nav';
import MobileHeader from './components/MobileHeader';
import MobileFooter from './components/MobileFooter';
import history from './helpers/history';


class App extends React.Component {


  render() {
    return (
      <Router history={history}>
        <div className="main gds-layout__row">
          <MobileHeader/>
          <div className="gds-flex gds-flex--wrap-no gds-flex--align-stretch gds-flex__item gds-flex--justify-stretch" style={{ overflow: 'hidden' }}>
              <Nav/>
              <Switch>
                <Route>
                  <Content/>
                </Route>
              </Switch>
          </div>
          <MobileFooter/>
        </div>
      </Router>
    );
  }
}

export default App;
