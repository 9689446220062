import React from 'react';
import mark from '../mark.svg';
import { withRouter } from 'react-router-dom';
import { navContent } from '../constants/constants';

class MobileHeader extends React.Component {

  render() {
    


    const pathname = this.props.location.pathname;
    let unit;
    //   unit = pathname === '/' ? '' : navContent.map((category) => {
    //   return category.units.filter( (unit) => { return unit.path ===  pathname});
    // }).filter( (el) => { return el.length > 0})[0][0].label
    switch(pathname) {
      case '/':
      case '/home':
        unit = '';
        break;
      case '/curator':
        console.log('curator!');
        unit = '';
        break;
      default:
        unit = navContent.map((category) => {
          return category.units.filter( (unit) => { return unit.path ===  pathname});
        }).filter( (el) => { return el.length > 0})[0][0].label
    }
    const params = new URLSearchParams(this.props.location.search),
      isolated = params.get('isolated'),
      headerClass = !isolated ? 'mobile-header' : 'mobile-header mobile-header--always-on',
      headerText = !isolated ? 'Product Specs' : `${unit} Specs`,
      textClass = headerText.length > 25 &&  window.innerWidth < 700 ? 'gds-text--header-xs' : 'gds-text--header-md';
    return (
      <div className={headerClass}>
        <div className="mobile-header__inner">
          <span className="divider-horizontal"/>
          <h1 className={`${textClass} gds-text--hero -text-right -text-tr-up -m-r-2`}>{headerText}</h1><img alt="mark" src={mark} height="21"/>
        </div>
      </div>
    )
  }
}

export default withRouter(MobileHeader);
