import React from 'react';
import { withRouter } from 'react-router-dom';
import { navContent } from '../constants/constants';
import CuratorSection from './CuratorSection';

class Curator extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedUnits: [],
      firstSelected: null
    }
  }

  generateLink = () => {
    console.log('generate link');
    const string = encodeURIComponent(this.state.selectedUnits.toString());

    const hostname = window.location.host;
    window.open(`${window.location.protocol}//${hostname}${this.state.firstSelected}?curate=${string}`);
  }

  reset = () => {
    window.location.reload();
  }

  selectItem = (e) => {
    const units = this.state.selectedUnits;
    const firstSelected = this.state.firstSelected === null ? e.target.getAttribute('data-path') : this.state.firstSelected;
    e.target.checked ? units.push(e.target.value) : units.splice(units.indexOf(e.target.value), 1);
    this.setState({
      selectedUnits: units,
      firstSelected: firstSelected
    });
  }

	render() {
    return (
      <div className="curator-content -p-a-4">
        <div className="gds-flex gds-flex--justify-between">
          {navContent.map((category) => {
              return (
                <CuratorSection key={category.key} handleChange={this.selectItem} category={category}/>
              )
            }) 
          }
        </div>
        <hr/>
        <div className="gds-flex">
          <button onClick={ this.generateLink } className="button -m-v-3 -m-r-2">Generate Link</button>
          <button onClick={ this.reset } className="button button--secondary -m-v-3">Reset</button>
        </div>
      </div>
    )
  }
}

export default withRouter(Curator);
