const contentUS = [
	{
		name: 'Proprietary',
		key: 0,
		units: [
			{
				label:'In-Screen',
				path: '/In-Screen',
				url: 'https://embed.getguru.com/card/cMKaXRki/InScreen-Specs',
				type: 'Proprietary',
				key: 0,
				id: 0
			},
			{
				label:'In-Screen Frame',
				path: '/In-Screen-Frame',
				url: 'https://embed.getguru.com/card/cBEqzLki/InScreen-Frame-Specs',
				type: 'Proprietary',
				key: 1,
				id: 1
			},
			{
				label:'In-Screen Expandable',
				path: '/In-Screen-Expandable',
				url: 'https://embed.getguru.com/card/T96ybe8c/InScreen-Expandable-Specs',
				type: 'Proprietary',
				key: 2,
				id: 2
			},
			{
				label:'In-Screen Expandable Video',
				path: '/In-Screen-Expandable-Video',
				url: 'https://embed.getguru.com/card/cnpxKRAi/InScreen-Expandable-Video-Specs',
				type: 'Proprietary',
				key: 3,
				id: 3
			},
			{
				label:'In-Image',
				path: '/In-Image',
				url: 'https://embed.getguru.com/card/TBEq9bAc/InImage-Specs',
				type: 'Proprietary',
				key: 4,
				id: 4
			},
			{
				label:'In-Image Expandable',
				path: '/In-Image-Expandable',
				url: 'https://embed.getguru.com/card/iXpxqRrT/InImage-Expandable-Specs',
				type: 'Proprietary',
				key: 5,
				id: 5
			},
			{
				label:'Canvas',
				path: '/Canvas',
				url: 'https://embed.getguru.com/card/cy6oxGdi/Canvas-Specs',
				type: 'Proprietary',
				key: 6,
				id: 6
			},
			{
				label:'6-Sec Video Canvas',
				path: '/6-Sec-Video-Canvas',
				url: 'https://embed.getguru.com/card/Tk6yqbMc/6Sec-Video-Canvas-Specs',
				type: 'Proprietary',
				key: 7,
				id: 7
			},
			{
				label:'In-Screen Expandable Corner',
				path: '/In-Screen-Expandable-Corner',
				url: 'https://embed.getguru.com/card/cAKnr9Ei/InScreen-Expandable-Corner',
				type: 'Proprietary',
				key: 8,
				id: 8
			},
			{
				label:'In-Article Shoppable',
				path: '/In-Article-Shoppable',
				url: 'https://embed.getguru.com/card/iEE9bkBT/InArticle-Shoppable',
				type: 'Proprietary',
				key: 9,
				id: 9
			},
			{
				label:'In-Article Video',
				path: '/In-Article-Video',
				url: 'https://embed.getguru.com/card/cL7nEBLi/InArticle-Video',
				type: 'Proprietary',
				key: 10,
				id: 25
			},
			{
				label:'Desktop Skin - Complex',
				path: '/Desktop-Skin-Complex',
				url: 'https://embed.getguru.com/card/ioRqp8RT/Complex-Desktop-Skin-Specs',
				type: 'Proprietary',
				key: 11,
				id: 26
			},
			{
				label:'Desktop Skin - Standard',
				path: '/Desktop-Skin-Standard',
				url: 'https://embed.getguru.com/card/T98RxMgc/Standard-Desktop-Skin-Specs',
				type: 'Proprietary',
				key: 12,
				id: 29
			},
			{
				label:'Desktop Skin - Express',
				path: '/Desktop-Skin-Express',
				url: 'https://embed.getguru.com/card/idzXzAdT/Express-Desktop-Skin-Specs',
				type: 'Proprietary',
				key: 13,
				id: 30
			},
			{
				label:'Desktop Skin - IAB',
				path: '/Desktop-Skin-IAB',
				url: 'https://embed.getguru.com/card/cMxzxxki/IAB-Banner-Desktop-Skin-Specs',
				type: 'Proprietary',
				key: 14,
				id: 31
			},
			{
				label:'Mobile Skin - Complex',
				path: '/Mobile-Skin-Complex',
				url: 'https://embed.getguru.com/card/inzgqGgT/Complex-InScreen-Cascade-Specs',
				type: 'Proprietary',
				key: 15,
				id: 27
			},
			{
				label:'Mobile Skin - Standard',
				path: '/Mobile-Skin-Standard',
				url: 'https://embed.getguru.com/card/T58R5bBc/Standard-InScreen-Cascade-Specs',
				type: 'Proprietary',
				key: 16,
				id: 32
			},
			{
				label:'Mobile Skin - Express',
				path: '/Mobile-Skin-Express',
				url: 'https://embed.getguru.com/card/idzG5XBT/Express-InScreen-Cascade-Specs',
				type: 'Proprietary',
				key: 17,
				id: 33
			},
			{
				label:'Mobile Skin - IAB',
				path: '/Mobile-Skin-IAB',
				url: 'https://embed.getguru.com/card/i7j97drT/IAB-Banner-InScreen-Cascade-Specs',
				type: 'Proprietary',
				key: 18,
				id: 23
			},
			{
				label:'Mobile Scroller',
				path: '/Mobile-Scroller',
				url: 'https://embed.getguru.com/card/iz8RdjyT/Mobile-Scroller',
				type: 'Proprietary',
				key: 19,
				id: 28
			},
			{
				label:'In-Game',
				path: '/In-Game',
				url: 'https://embed.getguru.com/card/TgjbbKyc/InGame-Banner-Specs',
				type: 'Proprietary',
				key: 20,
				id: 34
			},
			{
				label:'Hangtime',
				path: '/Hangtime',
				url: 'https://embed.getguru.com/card/Td5rL9nc/Hang-Time-Specs',
				type: 'Proprietary',
				key: 21,
				id: 35
			}
		]
	},
	{
		name: 'CTV/OTT',
		key: 1,
		units: [
			{
				label:'In-Video Corner',
				path: '/In-Video-Corner',
				url: 'https://embed.getguru.com/card/iXp99eyT/InVideo-Corner-Specs',
				type: 'Proprietary',
				key: 1,
				id: 24
			}
		]
	},
	{
		name: 'In-Article',
		key: 2,
		units: [
			{
				label:'In-Article Scroll',
				path: '/In-Article-Scroll',
				url: 'https://embed.getguru.com/card/iA9g9opT/InArticle-Scroll',
				type: 'In-Article',
				key: 0,
				id: 10
			}
		]
	},
	{
		name: 'Interactive',
		key: 3,
		units: [
			{
				label:'Carousel',
				path: '/Carousel',
				url: 'https://embed.getguru.com/card/ir6EEoLT/Interactive-Swipe-Specs',
				type: 'Interactive',
				key: 0,
				id: 12
			},
			{
				label:'Scroll',
				path: '/Scroll',
				url: 'https://embed.getguru.com/card/T5699a6c/Interactive-Scrollable-Specs',
				type: 'Interactive',
				key: 1,
				id: 13
			}
		]
	},
	{
		name: 'Standard',
		key: 4,
		units: [
			{
				label:'728x90',
				path: '/Standard-728x90',
				url: 'https://embed.getguru.com/card/co655E9i/Standard-728x90-Specs',
				type: 'Standard',
				key: 0,
				id: 14
			},
			{
				label:'320x50',
				path: '/Standard-320x50',
				url: 'https://embed.getguru.com/card/cp699Eri/Standard-320x50-Specs',
				type: 'Standard',
				key: 1,
				id: 15
			}
		]
	},
	{
		name: 'Display',
		key: 5,
		units: [
			{
				label:'320x50',
				path: '/Display-320x50',
				url: 'https://embed.getguru.com/card/ig6qbrXT/InSlot-320x50-Specs',
				type: 'Display',
				key: 0,
				id: 16
			},
			{
				label:'728x90',
				path: '/Display-728x90',
				url: 'https://embed.getguru.com/card/Tx6EzK8c/InSlot-728x90-Specs',
				type: 'Display',
				key: 1,
				id: 17
			},
			{
				label:'300x250',
				path: '/Display-300x250',
				url: 'https://embed.getguru.com/card/c86EEeji/InSlot-300x250-Specs',
				type: 'Display',
				key: 2,
				id: 18
			},
			{
				label:'160x600',
				path: '/Display-160x600',
				url: 'https://embed.getguru.com/card/cLEoo6ki/InSlot-160x600-Specs',
				type: 'Display',
				key: 3,
				id: 19
			},
			{
				label:'468x60',
				path: '/Display-468x60',
				url: 'https://embed.getguru.com/card/cy6EEMRi/InSlot-468x60-Specs',
				type: 'Display',
				key: 4,
				id: 20
			},
			{
				label:'640x480',
				path: '/Display-640x480',
				url: 'https://embed.getguru.com/card/ik655zpT/InSlot-640x480-Specs',
				type: 'Display',
				key: 5,
				id: 21
			},
			{
				label:'970x90',
				path: '/Display-970x90',
				url: 'https://embed.getguru.com/card/cAKLL6Gi/InSlot-970x90-Specs',
				type: 'Display',
				key: 6,
				id: 22
			}
		]
	}
]

const contentUK = [
	{
		name: 'Proprietary',
		key: 0,
		units: [
			{
				label:'In-Screen',
				path: '/In-Screen',
				url: 'https://embed.getguru.com/card/irA5M6oT/InScreen-UK-Specs',
				type: 'Proprietary',
				key: 0,
				id: 0
			},
			{
				label:'In-Screen Frame',
				path: '/In-Screen-Frame',
				url: 'https://embed.getguru.com/card/cMkX6K9i/InScreen-Frame-UK-Specs',
				type: 'Proprietary',
				key: 1,
				id: 1
			},
			{
				label:'In-Screen Expandable',
				path: '/In-Screen-Expandable',
				url: 'https://embed.getguru.com/card/cnAoA79i/InScreen-Expandable-UK-Specs',
				type: 'Proprietary',
				key: 2,
				id: 2
			},
			{
				label:'In-Screen Expandable Video',
				path: '/In-Screen-Expandable-Video',
				url: 'https://embed.getguru.com/card/cgA54e7i/InScreen-Expandable-Video-UK-Specs',
				type: 'Proprietary',
				key: 3,
				id: 3
			},
			{
				label:'In-Image',
				path: '/In-Image',
				url: 'https://embed.getguru.com/card/TKkr6pMc/InImage-UK-Specs',
				type: 'Proprietary',
				key: 4,
				id: 4
			},
			{
				label:'In-Image Expandable',
				path: '/In-Image-Expandable',
				url: 'https://embed.getguru.com/card/T4A5Mo5c/InImage-Expandable-UK-Specs',
				key: 5,
				id: 5
			},
			{
				label:'Canvas',
				path: '/Canvas',
				url: 'https://embed.getguru.com/card/TEg86pqc/Canvas-UK-Specs',
				type: 'Proprietary',
				key: 6,
				id: 6
			},
			{
				label:'6-Sec Video Canvas',
				path: '/6-Sec-Video-Canvas',
				url: 'https://embed.getguru.com/card/cRAajR7i/6Sec-Video-Canvas-UK-Specs',
				type: 'Proprietary',
				key: 7,
				id: 7
			},
			{
				label:'In-Screen Expandable Corner',
				path: '/In-Screen-Expandable-Corner',
				url: 'https://embed.getguru.com/card/iLg96E4T/InScreen-Expandable-Corner-UK-Specs',
				type: 'Proprietary',
				key: 8,
				id: 8
			},
			{
				label:'In-Article Shoppable',
				path: '/In-Article-Shoppable',
				url: 'https://embed.getguru.com/card/c5AqnaGi/InArticle-Shoppable-UK-Specs',
				type: 'Proprietary',
				key: 9,
				id: 9
			},
			{
				label:'In-Article Video',
				path: '/In-Article-Video',
				url: 'https://embed.getguru.com/card/cRAajKbi/InArticle-Video-UK-Specs',
				type: 'Proprietary',
				key: 10,
				id: 25
			},
			{
				label:'Desktop Skin',
				path: '/Desktop-Skin',
				url: 'https://embed.getguru.com/card/ieAnz4dT/Desktop-Skins-UK-Specs',
				type: 'Proprietary',
				key: 11,
				id: 26
			},
			{
				label:'Mobile Skin',
				path: '/Mobile-Skin',
				url: 'https://embed.getguru.com/card/ceAnz4Mi/Mobile-Skin-UK-Specs',
				type: 'Proprietary',
				key: 12,
				id: 27
			},
			{
				label:'Mobile Scroller',
				path: '/Mobile-Scroller',
				url: 'https://embed.getguru.com/card/iBgApLrT/Mobile-Scroller-UK-Specs',
				type: 'Proprietary',
				key: 13,
				id: 28
			}
		]
	},
	{
		name: 'CTV/OTT',
		key: 1,
		units: [
			{
				label:'In-Video Snipe',
				path: '/In-Video-Snipe',
				url: 'https://embed.getguru.com/card/TrA5M6ac/InVideo-Snipe-UK-Specs',
				type: 'Proprietary',
				key: 0,
				id: 23
			},
			{
				label:'In-Video Corner',
				path: '/In-Video-Corner',
				url: 'https://embed.getguru.com/card/cnAaR94i/InVideo-Corner-UK-Specs',
				type: 'Proprietary',
				key: 1,
				id: 24
			}
		]
	},
	{
		name: 'Social',
		key: 2,
		units: [
			{
				label:'Native',
				path: '/Native',
				url: 'https://embed.getguru.com/card/T6A5MzKc/Native-UK-Specs',
				type: 'Social',
				key: 0,
				id: 10
			},
			{
				label:'Story',
				path: '/Story',
				url: 'https://embed.getguru.com/card/TeAnzp6c/Story-UK-Specs',
				type: 'Social',
				key: 1,
				id: 11
			}
		]
	},
	{
		name: 'Interactive',
		key: 3,
		units: [
			{
				label:'Carousel',
				path: '/Carousel',
				url: 'https://embed.getguru.com/card/crA5MR7i/Interactive-Carousel-UK-Specs',
				type: 'Interactive',
				key: 0,
				id: 12
			},
			{
				label:'Scroll',
				path: '/Scroll',
				url: 'https://embed.getguru.com/card/cEg86EXi/Interactive-Scroll-UK-Specs',
				type: 'Interactive',
				key: 1,
				id: 13
			}
		]
	},
	{
		name: 'Standard',
		key: 4,
		units: [
			{
				label:'728x90',
				path: '/Standard-728x90',
				url: 'https://embed.getguru.com/card/TEg8kR8c/Standard-728x90-UK-Specs',
				type: 'Standard',
				key: 0,
				id: 14
			},
			{
				label:'320x50',
				path: '/Standard-320x50',
				url: 'https://embed.getguru.com/card/i5AqGzET/Standard-320x50-UK-Specs',
				type: 'Standard',
				key: 1,
				id: 15
			}
		]
	},
	{
		name: 'Display',
		key: 5,
		units: [
			{
				label:'320x50',
				path: '/Display-320x50',
				url: 'https://embed.getguru.com/card/T5AqGz8c/InSlot-320x50-UK-Specs',
				type: 'Display',
				key: 0,
				id: 16
			},
			{
				label:'728x90',
				path: '/Display-728x90',
				url: 'https://embed.getguru.com/card/iqA5GkjT/InSlot-728x90-UK-Specs',
				type: 'Display',
				key: 1,
				id: 17
			},
			{
				label:'300x250',
				path: '/Display-300x250',
				url: 'https://embed.getguru.com/card/iEg86oRT/InSlot-300x250-UK-Specs',
				type: 'Display',
				key: 2,
				id: 18
			},
			{
				label:'160x600',
				path: '/Display-160x600',
				url: 'https://embed.getguru.com/card/iMkX65zT/InSlot-160x600-UK-Specs',
				type: 'Display',
				key: 3,
				id: 19
			},
			{
				label:'468x60',
				path: '/Display-468x60',
				url: 'https://embed.getguru.com/card/ibAazjgT/InSlot-468x60-UK-Specs',
				type: 'Display',
				key: 4,
				id: 20
			},
			{
				label:'640x480',
				path: '/Display-640x480',
				url: 'https://embed.getguru.com/card/czA5Mpdi/InSlot-640x480-UK-Specs',
				type: 'Display',
				key: 5,
				id: 21
			},
			{
				label:'970x90',
				path: '/Display-970x90',
				url: 'https://embed.getguru.com/card/cBgApGgi/InSlot-970x90-UK-Specs',
				type: 'Display',
				key: 6,
				id: 22
			}
		]
	}
]

const navContent = process.env.REACT_APP_GEO === 'UK' ? contentUK : contentUS;

export {navContent};