import React from 'react';

class CuratorSection extends React.Component {
	
	render() {
    const { category } = this.props;
    return (
      <div className="-p-v-3 gds-flex__item" key={category.key}>
        <h4 className="gds-text--header-xs gds-text--bold -m-b-2">{category.name}</h4>
        <ul className={`nav-bar__menu-options`}>
          {
            category.units.map((item) => {
              return ( <li key={item.key} className=""><label><input onChange={this.props.handleChange} value={item.id} data-path={item.path} type="checkbox"/>{item.label}</label></li> )
            })
          }
        </ul>
      </div>
    )
  }
}

export default CuratorSection;
